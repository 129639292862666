import React from 'react';
import styled from 'styled-components';

const SectionStyle = styled.section`
  /* text-align: center; */
`;

type SectionProps = {
  className?: string;
  id: string;
  children: React.ReactNode
};
export default function Section({id, className, children}:SectionProps):JSX.Element{
  return <SectionStyle id={id} className={className}>
    <div className="container">
      {children}
    </div>
  </SectionStyle>;
}
